// wai-aria settings
%visualHidden {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

// placeholder color
@mixin placeholderColor($color) {
  &:placeholder-shown {
      color: $color;
  }
  &::-webkit-input-placeholder {
      color: $color;
  }
  &:-moz-placeholder {
      color: $color;
      opacity: 1;
  }
  &::-moz-placeholder {
      color: $color;
      opacity: 1;
  }
  &:-ms-input-placeholder {
      color: $color;
  }
}

// mediaQuery Settings
// https://github.com/paranoida/sass-mediaqueries

@mixin mq($args...) {
  $media-type: "only screen";
  $media-type-key: "media-type";
  $args: keywords($args);
  $expr: "";

  @if map-has-key($args, $media-type-key) {
    $media-type: map-get($args, $media-type-key);
    $args: map-remove($args, $media-type-key);
  }

  @each $key, $value in $args {
    @if $value {
      $expr: "#{$expr} and (#{$key}: #{$value})";
    }
  }

  @media #{$media-type} #{$expr} {
    @content;
  }
}

@mixin max($max) {
  @include mq($max-width: $max) {
    @content;
  }
}

@mixin min($min) {
  @include mq($min-width: $min) {
    @content;
  }
}

// browser Hack
@mixin browser_hack($browser: null) {
   // IE11
   @if $browser == "ie11" {
     @at-root _:-ms-fullscreen,:root & {
       @content;
     }
   }
   // @else if $browser == "" {
   // }
 }
