@charset "UTF-8";

@import "../../_various";
@import "normalize.css";

* {
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  background: #fff;
  font-family: Meiryo, "メイリオ", "Hiragino Kaku Gothic Pro",
    "ヒラギノ角ゴ Pro W3", Verdana, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  vertical-align: bottom;
  color: #333;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

article,
aside,
footer,
header,
main,
section {
  display: block;
}

main {
  padding-top: 80px;
  position: relative;
  z-index: 0;
  flex: 1 0 auto;
  @include max(767px) {
    padding-top: 56px;
  }
  &.sub_content {
    padding-top: 144px;
    padding-bottom: 121px;
    @include max(767px) {
      padding-top: 56px;
      padding-bottom: 80px;
    }
    &--Pb0 {
      padding-bottom: 0;
    }
  }
}

@include min(768px) {
  .header--Fixed + main.sub_content {
    padding-top: 90px;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
